import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import { transformToQueryParams } from 'utils';
import { initAccountingApi } from 'api';
import { FinancialBalance } from 'types/assetsAndLiabilities';

const useFinancialBalance = ({ personId, id }) => {
  const { householdId } = useParams();

  const queryKey = ['financial-balance', { householdId, personId, id }];

  const queryString = transformToQueryParams({ personId, householdId });

  const selectedId = id ? `/${id}` : '';
  const url = `v3/fbs${selectedId}?${queryString}`;

  const { data, isError, isFetched, isLoading, fetchStatus, isFetching } = useQuery(
    queryKey,
    async () => initAccountingApi().get<FinancialBalance>(url),
    {
      refetchOnMount: 'always',
    },
  );

  return {
    financialBalance: data?.data || [],
    isError,
    isLoadingFinancialBalance: isLoading,
    isFetched,
    isFetching,
    fetchStatus,
  };
};

export default useFinancialBalance;
