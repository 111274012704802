import * as yup from 'yup';
import { validationErrorMessages } from '@utils/form';
import { getLocale, i18n } from 'locales';
import getMinAndMaxDate from '@utils/date/getMinAndMaxDate';

export const maxAmount = {
  wohnflaeche: 9999.99,
  price: 999999999.99,
  capital: 999999999.99,
};

export const minAmount = {
  wohnflaeche: 0.01,
  verkehrswert: 0.01,
  price: 0.01,
  capital: 0.01,
};

export const maxLength = 50;

export const createValidationSchema = () => ({
  rentRealEstate: yup.object().shape({
    wohnflaeche: yup
      .number()
      .required(i18n.t('validation.fieldRequired'))
      .max(
        maxAmount.wohnflaeche,
        i18n.t('validation.exceededMaximumValue', {
          maxValue: maxAmount.wohnflaeche.toLocaleString(getLocale()),
        }),
      )
      .min(minAmount.wohnflaeche, i18n.t('validation.fieldMustBePositive')),
  }),
  ownRealEstate: yup.object().shape({
    name: yup
      .string()
      .required(i18n.t('validation.fieldRequired'))
      .max(maxLength, i18n.t('validation.maxLength', { maxLength })),
    art: yup.string().trim().required(i18n.t('validation.fieldRequired')),
    nutzung: yup.string().trim().required(i18n.t('validation.fieldRequired')),
    verkehrswert: yup
      .number()
      .notRequired()
      .nullable()
      .max(maxAmount.price, validationErrorMessages.setExceededMaxValueError(maxAmount.price))
      .min(minAmount.verkehrswert, i18n.t('validation.fieldMustBePositive')),
  }),
  buyRealEstate: ({ whenToBuyInitialValue }: { whenToBuyInitialValue: Date }) => {
    const minAndMaxDate = getMinAndMaxDate({ minDate: whenToBuyInitialValue });

    return yup.object().shape({
      whatToBuy: yup.string().required(i18n.t('validation.fieldRequired')).trim(),
      whenToBuy: yup
        .date()
        .nullable()
        .min(minAndMaxDate.minDate.validation.value, minAndMaxDate.minDate.validation.errorMessage)
        .max(minAndMaxDate.maxDate.validation.value, minAndMaxDate.maxDate.validation.errorMessage),
      capital: yup
        .number()
        .notRequired()
        .nullable()
        .max(maxAmount.price, validationErrorMessages.setExceededMaxValueError(maxAmount.capital))
        .min(minAmount.capital, i18n.t('validation.fieldMustBePositive')),
      price: yup
        .number()
        .notRequired()
        .nullable()
        .max(maxAmount.price, validationErrorMessages.setExceededMaxValueError(maxAmount.price))
        .min(minAmount.price, i18n.t('validation.fieldMustBePositive')),
      siteExists: yup.boolean().notRequired(),
    });
  },
});

export default createValidationSchema;
